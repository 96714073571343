import React from 'react';
import { DataContext } from '../../lib/context';
import { Events } from '../../lib/events'
import { showLoader, showToast, validateEmail, clone, wait } from '../common'
import { getBase, postBase, deleteBase, putBase } from '../../lib/api'
import { BiSolidInfoCircle } from 'react-icons/bi'

const pwdPatt = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*_-]).{8,}$'
const re = new RegExp(pwdPatt);
const pwdInfo = 'Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character'
function ChangePassword({ force }) {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)

    const [form, setForm] = React.useState({ oldPassword: '', newPassword: '', newPassword2: '' });
    const [formError, setFormError] = React.useState({ oldPassword: true, newPassword: true, newPassword2: true });


    const validateForm = formData => {
        let newFormError = { oldPassword: true, newPassword: true, newPassword2: true }
        newFormError.oldPassword = (formData.oldPassword.trim() === '')
        newFormError.newPassword = !re.test(formData.newPassword.trim())
        newFormError.newPassword2 = !(formData.newPassword.trim() === formData.newPassword2.trim())
        setFormError(newFormError)
    }

    const handleFormChange = React.useCallback((p, val) => {
        setForm(oldForm => {
            oldForm[p] = val
            validateForm(oldForm)
            return oldForm
        })
    })


    //add new
    const handleSave = React.useCallback(async (formData) => {
        showLoader(true)
        console.log(form)
        const result = await events.changePassword(state.token, form.oldPassword, form.newPassword)
        console.log('change Pwd:', result)
        if(result.error){
            showToast(result.error, 'error')
        }else{
            showToast('Password changed', 'info')
            events.showChangePassword(false)
        }
        showLoader(false)
    })

    console.log('formerror', formError)

    return (
        <div className='fixed inset-0 bg-tp-70 z-50'>
            <div className='relative bg-gray-300 p-2 border flex flex-col left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-64 w-52 overflow-hidden'>
                <div className='text-white p-1 text-center' style={{ backgroundColor: 'rgb(35, 64, 117 )' }}>Change Password</div>
                <div className='grow'></div>

                <div className='flex flex-row'>
                    <div>Old password</div>
                    <span title='old password'> <BiSolidInfoCircle className='ml-1 h-6' style={{ fill: formError.oldPassword ? 'red' : '' }} /></span>
                </div>
                <input type="password" onChange={e => handleFormChange('oldPassword', e.target.value)} />

                <div className='flex flex-row'                >
                    <div>New password</div>
                    <span title={pwdInfo}> <BiSolidInfoCircle className='ml-1 h-6' style={{ fill: formError.newPassword ? 'red' : '' }}/></span>
                </div>
                <input type="password" onChange={e => handleFormChange('newPassword', e.target.value)} />

                <div className='flex flex-row'                >
                    <div>Repeat new password</div>
                    <span title='Repeat new password'> <BiSolidInfoCircle className='ml-1 h-6' style={{ fill: formError.newPassword2 ? 'red' : '' }}/></span>
                </div>
                <input type="password" onChange={e => handleFormChange('newPassword2', e.target.value)} />

                <div className='grow'></div>
                <div className='flex flex-row'>
                    <button
                        disabled={Object.keys(formError).some(p => formError[p] === true)}
                        className='button w-20'
                        onClick={handleSave}
                    >Save</button>
                    <div className='grow'></div>
                    {force ? null : <button
                        className='button w-20'
                        onClick={()=>events.showChangePassword(false)}
                    >Cancel</button>}
                </div>
            </div>
        </div>
    );
}


export default React.memo(ChangePassword)