import React from 'react';

//add 'loader.css' support in index.html

function Loader() {
    return (
        <div
            id='loader'
            className='ew-loader-container'>
            <div className="ew-loader"><div></div><div></div><div></div></div>
            <div id='loader-txt' className="ew-loader-message">loading</div>
        </div>
    );
}


export default React.memo(Loader)