import React from 'react';
import { addMilliseconds, parseISO, format } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}

function secondsToReadable(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return pad(hours) + ':' + pad(minutes) + ':' + pad(remainingSeconds);
}

function getLocalTime(str) {
  try {
    const timeZone = 'Canada/Pacific';
    const utcDate = parseISO(str);
    const offSet = getTimezoneOffset(timeZone);
    const offsetTime = addMilliseconds(utcDate, offSet);
    return format(offsetTime, 'yyyy-MM-dd HH:mm:ss');
  } catch (error) {
    console.log(error);
    return '';
  }
}

const noop = (v) => v;

const roundNumber = (value) => {
  const rounded = Math.round(value);
  if (isNaN(rounded)) return value;

  return rounded;
};

const formatters = {
  agents_online: roundNumber,
  agents_npt: roundNumber,
  in_queue: roundNumber,
  oldest: secondsToReadable,
  queued: roundNumber,
  handled: roundNumber,
  abandoned: roundNumber,
  average_handle_time: secondsToReadable,
  sl_60: roundNumber,
  average_queue_answer_time: secondsToReadable,
  staffed: roundNumber,
  on_contact: roundNumber,
};

const tooltips = {
  in_queue: 'Count of customers waiting in queue',
  oldest: 'Age of contact that has been in queue the longest',
  average_queue_answer_time: 'Average time customer spent in queue before being connected to an agent',
  queued: 'Count of contacts placed into queue',
  handled: 'Count of contacts handled by an agent',
  abandoned: 'Count of contacts that were disconnected while the customer was in queue',
  average_handle_time: 'Average Handle Time',
  agents_online: 'Count of agents in a status other than Offline',
  on_contact: 'Count of agents on contact',
  agents_npt: 'Count of agents in non-productive statuses',
  staffed: 'Count of agents who are online, and not in NPT',
};

function DashboardTablet({ itemName, itemNameDisplay, cache, error }) {
  const { columns, itemBase, itemNames, queueNames, data } = cache;

  let newRows = React.useMemo(() => {
    let newItems = {};
    for (let col of columns) {
      newItems[col] = { ...itemBase };
    }
    for (let item of data) {
      for (let col of columns) {
        newItems[col][item.queue_name] = item.stats[col];
      }
    }
    return newItems;
  }, [cache]);

  const showRounded = (item, data) => {
    return Math.round(data) || data;
  };

  return cache.data ? (
    <>
      <table className="tablecontainer">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {Object.keys(queueNames).map((item) => (
              <th key={item}>
                <h2>{queueNames[item]}</h2>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(newRows).map((row) => {
            const formatter = formatters[row] || noop;
            const tooltip = tooltips[row] || '';
            return (
              <tr key={row}>
                <td className="p-1" title={tooltip}>
                  {itemNames[row]}
                </td>
                {Object.keys(newRows[row]).map((item) => (
                  <td className="p-1" key={item}>
                    {formatter(newRows[row][item])}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ textAlign: 'right' }}>
        <span className="italic text-gray-500">Last update: {getLocalTime(cache.lastUdpate)}</span>
        <br /> {error ? <a className="text-red-300">({error})</a> : ''}
      </div>
    </>
  ) : (
    ''
  );
}

export default React.memo(DashboardTablet);
