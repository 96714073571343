import React from 'react';
import { DataContext } from '../../lib/context';
import { Events } from '../../lib/events'
import { showLoader, showToast, validateEmail, clone, wait } from '../common'
import { getBase, postBase, deleteBase, putBase } from '../../lib/api'
import { BiPlusMedical, BiError, BiCheckCircle, BiCircle, BiArrowBack } from 'react-icons/bi'

function Roles(props) {
    const { value, onChange, value1, value2 } = props

    function handleChange() {
        onChange(value === value1 ? value2 : value1)
    }

    return (
        <div className='flex flex-row items-center gap-1 cursor-pointer' onClick={handleChange}>
            <div>{value === value1 ?
                <BiCheckCircle className='fill-blue-600' /> :
                <BiCircle className='fill-slate-500' />}
            </div>
            <div>{value}</div>
        </div>
    )
}

function NewUser(props) {
    const { onCancel, onSave, initData, onUpdate } = props
    const [EmailError, setEmailError] = React.useState(initData.email === '')
    const [FormData, setFormData] = React.useState({
        email: "",
        password: "abc123",
        userType: "",
        role: "",
        enabled: true
    })

    React.useEffect(() => {
        setFormData(initData)
    }, [initData])

    function emailChange(e) {
        const value = e.target.value.trim()
        const result = validateEmail(value)
        // console.log('users.js(18) >  validateEmail:', result);
        if (result) {
            setEmailError(false)
            setFormData(f => {
                let newF = clone(f)
                newF.email = value
                if (value.endsWith('dxc.com') || value.endsWith('dxcas.com')) {
                    newF.userType = 'dxc'
                    newF.role = 'admin'
                } else {
                    newF.userType = 'nondxc'
                    newF.role = 'user'
                }
                return newF
            })

        } else {
            setEmailError(true)
            setFormData(f => {
                let newF = clone(f)
                newF.email = value
                newF.userType = ''
                newF.role = ''
                return newF
            })
        }
    }

    function handleSave() {
        if (initData.email === '') {
            if (!EmailError)
                onSave(FormData)
        } else {
            onUpdate(FormData)
        }
    }

    function handleCancel() {
        onCancel()
    }

    function handleRoleChange(r) {
        setFormData(f => {
            let newF = clone(f)
            newF.role = r
            return newF
        })
    }

    function handleEnabledChange(b) {
        setFormData(f => {
            let newF = clone(f)
            newF.enabled = b === 'true'
            return newF
        })
    }

    return (
        <div className='fixed inset-0 bg-tp-70 z-50'>
            <table className='form-table relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                <thead>
                    <tr>
                        <th className='text-lg' colSpan={3}>New User</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className='w-10'>{EmailError ? <BiError /> : ''}</td>
                        <td className='w-40'>Email</td>
                        <td >{initData.email === '' ?
                            <input className='w-80 p-2 text-neutral-800' type='text' onChange={emailChange}
                                value={FormData.email} />
                            : initData.email
                        }
                        </td>
                    </tr>
                    <tr >
                        <td className='w-1'></td>
                        <td>Password</td>
                        <td>{initData.email === '' ? 'abc123' : '***'}</td>
                    </tr>
                    <tr >
                        <td className='w-1'></td>
                        <td>User Type</td>
                        <td>{FormData.userType}</td>
                    </tr>
                    <tr >
                        <td className='w-1'></td>
                        <td>Role</td>
                        <td>{FormData.userType === 'dxc' ?
                            <Roles value={FormData.role} value1='admin' value2='user' onChange={handleRoleChange} />
                            : FormData.role}</td>
                    </tr>
                    {initData.email !== '' ?
                        <tr >
                            <td className='w-1'></td>
                            <td>Enabled</td>
                            <td>
                                <Roles value={FormData.enabled.toString()} value1='true' value2='false' onChange={handleEnabledChange} />
                            </td>
                        </tr> : ''}
                    <tr >
                        <td className='w-1'></td>
                        <td></td>
                        <td className='text-right'>
                            <button className='button mr-2' onClick={handleSave} disabled={EmailError}>Save</button>
                            <button className='button' onClick={handleCancel}>Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

function DeleteUser(props) {
    const { email, onCancel, onDelete } = props

    function handleDelete() {
        onDelete(email)
    }

    function handleCancel() {
        onCancel()
    }

    return <div className='fixed inset-0 bg-tp-70'>
        <table className='form-table relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <thead>
                <tr>
                    <th className='text-lg' colSpan={2}>Delete User</th>
                </tr>
            </thead>
            <tbody>
                <tr >
                    <td className='w-40'>Email</td>
                    <td >{email}</td>
                </tr>
                <tr >
                    <td className='text-right' colSpan={2}>
                        <button className='button mr-2' onClick={handleDelete}>Delete</button>
                        <button className='button' onClick={handleCancel}>Cancel</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}

function Users() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)
    const [list, setList] = React.useState([])
    const [initFormData, setInitFormData] = React.useState(null)
    const [toDelete, setToDelete] = React.useState('')

    async function getData(retry = 0) {
        showLoader(true)
        const result = await getBase(state.token, '/api/master/users')
        if (result.error) {
            if (result.error === 'invalid token') {
                if (retry < 5) {
                    await wait(500)
                    console.log('retry user - getData: ', retry)
                    await getData(retry + 1)
                } else {
                    events.logout()
                    showToast('invalid token. login required for users module.', 'error')
                }
            } else {
                showToast(result.error, 'error')
            }
        } else {
            // console.log('users.js(18) > users.getdata :', result);
            setList(result)
        }
        showLoader(false)
    }

    //add new
    async function handleSave(formData, retry = 0) {
        showLoader(true)
        const result = await postBase(state.token, '/api/master/users', formData)
        console.log('users.js(162) > add new :', result.error);
        if (result.error) {
            if (result.error === "uniqueViolated") {
                showToast('duplicated email', 'error')
            } else if (result.error === 'invalid token') {
                if (retry < 5) {
                    await wait(1500)
                    console.log('retry: ', retry)
                    await handleSave(formData, retry + 1)
                } else {
                    showToast('invalid token. login required.', 'error')
                }
            } else {
                showToast(result.error, 'error')
            }
        } else {
            await getData()
            setInitFormData(null)
        }
        showLoader(false)
    }

    async function handleUpdate(formData, retry = 0) {
        console.log('users.js(231) > begin update ');
        showLoader(true)
        const result = await putBase(state.token, '/api/master/users', formData)
        // console.log('users.js(234) > update :', result);
        if (result.error) {
            if (result.error === 'invalid token') {
                if (retry < 5) {
                    await wait(1500)
                    console.log('retry: ', retry)
                    await handleUpdate(formData, retry + 1)
                } else {
                    showToast('invalid token. login required.', 'error')
                }
            } else {
                showToast(result.error, 'error')
            }
        } else {
            await getData()
            setInitFormData(null)
        }
        showLoader(false)
    }

    async function handleDelete() {
        showLoader(true)
        const result = await deleteBase(state.token, '/api/master/users', { email: toDelete })
        setToDelete('')
        await getData()
        showLoader(false)
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <div className='fixed inset-0 bg-tp-70 z-50'>
            <div className='relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-4/5 w-4/5 overflow-auto'>
                <table className='masterTable w-full' >
                    <thead className='sticky top-0'>
                        <tr className='' style={{backgroundColor:'#1F2739'}}>
                            <td className=' text-lg font-bold p-2' colSpan={4}>Users Maintenance</td>
                            <td className=' float-right mr-2'>
                                <button className='mt-1 p-2 hover:bg-red-900' onClick={e => setInitFormData({
                                    email: "",
                                    password: "abc123",
                                    userType: "",
                                    role: "",
                                    enabled: true
                                })}>
                                    <BiPlusMedical />
                                </button>
                                <button className='mt-1 p-2 hover:bg-red-900' onClick={e => events.closePage('users')} title='back'>
                                    <BiArrowBack />
                                </button>
                            </td>
                        </tr>
                        <tr  style={{backgroundColor:'#1F2739'}}>
                            <th>email</th>
                            <th>type</th>
                            <th>role</th>
                            <th>Enabled</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(item => <tr key={item.email}>
                            <td>{item.email}</td>
                            <td>{item.userType}</td>
                            <td>{item.role}</td>
                            <td>{item.enabled ? <BiCheckCircle className='fill-blue-600' /> : <BiCircle className='fill-slate-500' />}</td>
                            <td>
                                <button className='button mr-2' onClick={e => setInitFormData(item)}>edit</button>
                                <button className='button' onClick={e => setToDelete(item.email)}>remove</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>

            {initFormData !== null ? <NewUser initData={initFormData} onCancel={e => setInitFormData(null)} onSave={handleSave} onUpdate={handleUpdate} /> : null}
            {toDelete === '' ? '' : <DeleteUser email={toDelete} onCancel={e => setToDelete('')} onDelete={handleDelete} />}
        </div>
    );
}


export default React.memo(Users)