
/** dateInStr(new Date()) > "2023-06-27" */
const dateInStr = d => d.toISOString().split('T')[0]

const wait = async (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const randomString = () => Math.random().toString(36).slice(2);

const generateRandomUUID = (a) => (a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateRandomUUID));

//#region object
/** isEmptyObject({}) > true */
const isEmptyObject = obj => Object.keys(obj || {}).length === 0

const cloneObject = obj => JSON.parse(JSON.stringify(obj))
//#endregion 

//#region array
/** isAnagram('listen', 'silent') > true */
const isAnagram = (str1, str2) => ([...str1.toLowerCase()].sort().join('') === [...str2.toLowerCase()].sort().join(''));

/** merge and remove duplicate */
const mergeArrays = (arr1, arr2) => [...new Set([...arr1, ...arr2])];

/** generate an array of numbers from 1 to n */
const genArray = n => [...Array(n).keys()].map(i => i + 1);

/** shuffleArray([1,2,3,4,5]) > [4,1,2,5,3] */
const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);

/** 
 * const arr = [
 * 
 * { id: 1, name: 'home' }, 
 * 
 * { id: 2, name: 'office' }, 
 * 
 * { id: 3, name: 'home' }
 * 
 * ]
 * 
 * result:
 * 
 * { 
 * 
 *      home: [ { id: 1, name: 'home' }, { id: 3, name: 'home' } ], 
 * 
 *      office: [ { id: 2, name: 'office' } ] 
 * 
 * }
*/
const groupArrayByProp = (arr, prop) => arr.reduce((result, obj) => (result[obj[prop]] = [...(result[obj[prop]] || []), obj], result), {})

const sortArrayByProp = (arr, key) => arr.sort((a, b) => a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0);

//#endregion

module.exports = {
    dateInStr,
    wait,
    generateRandomUUID,
    randomString,
    isEmptyObject,
    cloneObject,
    isAnagram,
    mergeArrays,
    genArray,
    shuffleArray,
    groupArrayByProp,
    sortArrayByProp
}
