import { serverUrl } from "./config";
import base64 from "base-64";

const deleteBase = async function (token, url, doc) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(doc),
        redirect: "follow",
    };
    try {
        const res = await fetch(serverUrl + url, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("API POST Error: ", error);
        return { error };
    }
}
const putBase = async function (token, url, doc) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(doc),
        redirect: "follow",
    };
    try {
        const res = await fetch(serverUrl + url, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("API POST Error: ", error);
        return { error };
    }
}
const postBasicAuth = async function (url, email, password) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'Basic ' + base64.encode(email + ":" + password));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ email });
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const res = await fetch(serverUrl + url, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("API POST Error: ", error);
        return { error };
    }
}

const postBase = async function (token, url, doc) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(doc),
        redirect: "follow",
    };
    try {
        const res = await fetch(serverUrl + url, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("API POST Error: ", error);
        return { error };
    }
}
const getBase = async (token, url) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };
    try {
        const res = await fetch(serverUrl + url, requestOptions);
        return await res.json();
    } catch (error) {
        console.log("API Get Error: ", error, serverUrl + url);
        return { error };
    }
}

export {
    postBasicAuth,
    postBase,
    getBase,
    putBase,
    deleteBase
};
