import React from 'react';
import { DataContext } from '../lib/context';
import { Events } from '../lib/events'

function Footer() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)

    let progress = 0

    function doProgress() {
        progress += 0.1
        let pBar = document.getElementById('progress_bar')
        if (pBar !== null) {
            pBar.style.width = progress + '%'
            setTimeout(() => {
                if (progress < 100) {
                    //do nothing
                } else {
                    progress = 0
                    //process request here
                }
                doProgress()
            }, 50)
        }
    }

    React.useEffect(() => {
        // doProgress()
    }, [])

    return (
        <>
            <div id='progress_bar' className='progress-bar' ></div>
        </>
    );
}


export default React.memo(Footer)
