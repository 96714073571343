import React from 'react';
import { DataContext } from '../../lib/context';
import { Events } from '../../lib/events'

export default function Menu() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)

    const items = [
        {
            key: 'home',
            text: 'Home',
            onClick: e => { }
        }
    ]

    if(state.role === 'admin'){
        items.push(
            {
                key: 'users',
                text: 'Users',
                onClick: e => { }
            });
            // items.push(
            //     {
            //         key: 'queues',
            //         text: 'Queues',
            //         onClick: e => { }
            //     })
    }

    const MenuItem = ({ item }) => {
        const selectedBg = state.pageId === item.key ?
            'border-neutral-900' : 
            ''

        return <div
            className={'p-3 cursor-pointer hover:bg-black hover:border-neutral-900 border-l-8 border-cl ' + selectedBg}
            style={{ borderLeftColor : state.pageId === item.key ?
                'rgb(35, 64, 117)' : 
                'rgb(35, 64, 117 )' }}
            onClick={e => events.toPage(item.key, {})}
        >{item.text}</div>
    }

    return (
        <>
            <div className='flex flex-col'>
                {items.map(item => <MenuItem key={item.key} item={item} />)}
            </div>
        </>
    );
}
