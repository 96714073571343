import React from "react";
import ReactDOM from "react-dom";

import App from './components/app'
import { DataProvider } from './lib/context';
import {initState} from './lib/events'

import './components/common/modal.css'

ReactDOM.render(
    <DataProvider initState={initState}><App /></DataProvider>,
    document.getElementById("root")
);