import Home from './home'
import Table from './table'
import Users from './users'
import Queues from './queues'

export default {
    home: { display: 'Home', comp: Home },
    table: { display: 'Table', comp: Table },
    users: { display: 'Users', comp: Users },
    queues: { display: 'Queues', comp: Queues },
}
