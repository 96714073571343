import React from 'react';
import { DataContext } from '../../lib/context';
import { Events } from '../../lib/events'
import { getBase } from '../../lib/api'
import { showLoader, showToast, validateEmail, clone } from '../common'
import DashboardItem from './dashboardItem'

var timeoutList = {}

function addTimeOutObj(name, timeOutId) {
    // console.log('home.js(11) >  addTimeOutObj:', name, timeOutId);
    timeoutList[name] = timeOutId
}
function removeTimeOutObj(name) {
    let timeoutId = timeoutList[name]
    // console.log('home.js(15) > removeTimeOutObj :', timeoutId);
    clearTimeout(timeoutId)
    timeoutList[name] = ''
}

export default function Home() {
    const { state, update } = React.useContext(DataContext);
    const [dashboardConfig, setDashboardConfig] = React.useState([])
    const events = new Events(update)

    async function getConfig(retry) {
        const result = await getBase(state.token, '/api/dashboardConfig')
        if (result.error) {
            if (retry < 10)
                getConfig(retry + 1)
            else
                showToast(result.error, retry, 'error')
        } else {
            setDashboardConfig(result)
        }
    }

    React.useEffect(() => {
        getConfig(0)
    }, [])

    return (
        <>
            {dashboardConfig.map(config =>
                <DashboardItem
                    key={config.name}
                    token={state.token}
                    itemName={config.name}
                    itemPullInterval={config.pullInterval}
                    addTimeOutObj={addTimeOutObj}
                    removeTimeOutObj={removeTimeOutObj}
                />
            )}

        </>
    );
}
