import React from 'react';
import { DataContext } from '../lib/context';
import { Events } from '../lib/events'
import Logo from '../BC_logo.jpg'
import { BiLogOutCircle,  } from 'react-icons/bi'

export default function Header() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)

    function handleLogout(){
        events.logout()
    }

    return (
        <div className='header-container'>
            <img src={Logo} style={{ height: '80px', padding: '15px'}}></img>
            <div className='header-title ' style={{color: 'rgb(35, 64, 117 )'}}>SDS Dashboard</div>
            <div className='grow' />
            <div className='header-user '>User : {state.email}</div>
            {state.isCustomer ? <div
                onClick={() => events.showChangePassword(true)}
            className='header-user hover:underline cursor-pointer'>(change Password)</div> : '' }
            <div className='header-button' onClick={handleLogout} title='logout' placeholder='logout'><BiLogOutCircle /></div>
        </div>
    );
}
