import React from 'react';
import { DataContext } from '../../lib/context';
import { Events } from '../../lib/events'
import { showLoader, showToast, validateEmail, clone } from '../common'
import { getBase, postBase, deleteBase, putBase } from '../../lib/api'
import { BiArrowBack } from 'react-icons/bi'
import { cloneObject } from '../../lib/jsTools'

function ItemTable({ itemData, onUpdate, onclose }) {
    const [newEmail, setNewEmail] = React.useState('')
    const [oldFormData, setOldFormData] = React.useState(null)
    const [formData, setFormData] = React.useState(null)

    async function enterEditMode() {
        setOldFormData(formData)
    }

    async function exitEditMode() {
        setFormData(oldFormData)
        setOldFormData(null)
    }
    async function handleAdd() {
        console.log('handleAdd')
        if (newEmail.length > 0) {
            setNewEmail('')
            setFormData(oldData => {
                let newData = cloneObject(oldData)
                newData.reqBody.name_list.push(newEmail)
                newData.reqBody.name_list.sort()
                return newData
            })
        }
    }

    async function handleUpdate() {
        onUpdate(formData)
    }

    async function handleDelete(email) {
        setFormData(oldData => {
            let newData = cloneObject(oldData)
            newData.reqBody.name_list = newData.reqBody.name_list.filter(item => item !== email)
            return newData
        })
    }

    React.useEffect(() => {
        setFormData(itemData)
    }, itemData)

    return (
        <>
            {formData ? <div className='border border-gray-800 flex flex-col items-stretch'>
                <div className='text-gray-100 p-2 flex flex-row' style={{ backgroundColor: '#1F2739' }}>
                    <a>{formData.name}</a>
                    <a className='grow'></a>
                    {oldFormData ? '' : <button className='px-2 hover:bg-slate-700'
                        onClick={enterEditMode}>update</button>}
                    <button className='mt-1 p-2 hover:bg-red-900' onClick={e => onclose()} title='back'>
                        <BiArrowBack />
                    </button>
                </div>
                {formData.reqBody.name_list.map(email => (
                    <div key={email} className='p-2 odd:bg-gray-300 even:bg-gray-200 flex flex-row' >
                        <a>{email}</a>
                        <a className='grow'></a>
                        {oldFormData ? <button className='px-2 hover:bg-slate-400'
                            onClick={() => handleDelete(email)}>x</button> : ''}
                    </div>
                ))}
                {oldFormData ? (
                    <>
                        <div className='p-2 flex flex-row' >
                            <input className='grow' value={newEmail} onChange={e => setNewEmail(e.target.value)} />
                            <button className='px-2 hover:bg-slate-400'
                                onClick={handleAdd}>Add</button>
                        </div>
                        <div className='text-gray-100 p-2 flex flex-row' style={{ backgroundColor: '#1F2739' }}>
                            <button className='px-2 hover:bg-slate-700'
                                onClick={handleUpdate}>Save</button>
                            <a className='grow'></a>
                            <button className='px-2 hover:bg-slate-700'
                                onClick={exitEditMode}>Cancel</button>
                        </div>
                    </>
                ) : ''}
            </div>
                : ''}
        </>
    )
}

function Queues() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)
    const [newEmail, setNewEmail] = React.useState('')
    const [oldData, setOldData] = React.useState(null)
    const [list, setList] = React.useState([])

    async function getData() {
        showLoader(true)
        let result = await getBase(state.token, '/api/master/tableConfig')
        if (result.error) {
            if (result.error === 'invalid token') {
                events.logout()
                showToast('invalid token. login required.', 'error')
            } else {
                showToast(result.error, 'error')
            }
        } else {
            for (let item of result) {
                item.reqBody.name_list.sort()
            }

            setList(result)
        }
        showLoader(false)
    }

    async function handleUpdate(item) {
        console.log('users.js(231) > begin update ');
        showLoader(true)
        const result = await putBase(state.token, '/api/master/tableConfig', item)
        if (result.error) {
            showToast(result.error, 'error')
        } else {
            // await getData()
            setOldData(null)
        }
        showLoader(false)
    }

    function handleClose(){
        events.closePage('queues')
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        state.showQueues ? <div className='fixed inset-0 bg-tp-70 z-50'>
            <div className='w-80 flex flex-col gap-2 relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                {list.map(item =>
                    <>
                        <ItemTable itemData={item} onUpdate={handleUpdate} onclose={handleClose} />
                    </>
                )}
            </div>
        </div> : ''
    );
}


export default React.memo(Queues)