import pages from '../components/pages/_index'
import { getBase, postBase, postBasicAuth } from './api'
import { showLoader, showToast, validateEmail, clone } from '../components/common'

const initState = {
    token: '',
    nameChangedMessage: 'No Changed',
    nameChangedcount: 0,
    name: 'World',
    pageId: 'home',
    showUsers: false,
    showQueues: false,
    showChangePassword: false,
    forceChangePassword: false,
    pages
};

function Events(udpate) {
    this.changeName = name => {
        udpate(state => {
            return ({ name, nameChangedcount: state.nameChangedcount + 1 })
        })
    }
    this.toPage = (pageId, data) => {
        if (pageId === 'users') {
            udpate(state => {
                return ({ showUsers: true })
            })
        }
        if (pageId === 'queues') {
            udpate(state => {
                return ({ showQueues: true })
            })
        }
    }
    this.closePage = (pageId, data) => {
        if (pageId === 'users') {
            udpate(state => {
                return ({ showUsers: false })
            })
        }
        if (pageId === 'queues') {
            udpate(state => {
                return ({ showQueues: false })
            })
        }
    }
    this.addCount = () => {
        udpate(state => ({ nameChangedcount: state.nameChangedcount + 1 }))
    }
    this.userLogin = async (email, password) => {
        console.log('events.js(35) > user login api called');
        const result = await postBasicAuth('/auth/userlogin', email, password)
        if (result.error) {
            return result
        } else {
            console.log('events.js(45) > app certificate: ', result);
            udpate(state => {
                return ({
                    token: result.token,
                    email: result.email,
                    role: result.role,
                    forceChangePassword: result.changepwd || false,
                    isCustomer: result.isCustomer
                })
            })

            return null
        }
    }

    this.dxcLogin = async token => {
        // console.log('events.js(54) > dxclogin api called');
        const result = await getBase(token, '/auth/dxclogin')
        if (result.error) {
            return result
        } else {
            // console.log('events.js(45) > app certificate: ', result);
            udpate(state => {
                return ({
                    token: result.token,
                    email: result.email,
                    role: result.role
                })
            })

            return {
                token: result.token,
                email: result.email,
                role: result.role
            }
        }
    }

    this.changePassword = async (token, oldPassword, newPassword) => {
        const result = await postBase(token, '/api/dashboardUserPwd', { oldPassword, newPassword })
        //make sure disable force password change
        console.log('change a password', result)
        udpate(state => {
            return ({
                forceChangePassword: false
            })
        })
        return result
    }

    this.showChangePassword = b => {
        udpate(state => {
            return ({
                showChangePassword: b
            })
        })
    }

    //TODO: <<<Testing
    this.testLogin = async token => {
        console.log('Test login');
        udpate(state => {
            return ({
                token: token,
                email: 'abc@dxc.com',
                role: 'admin'
            })
        })

        return null
    }
    //TODO: Testing>>>

    this.toast = (msg, type) => {
        if (msg === 'invalid token') {
            this.logout()
            showToast('invalid token. login required.', 'error')
        } else {
            showToast(msg, type)
        }
    }

    this.logout = async () => {
        udpate(state => {
            return ({
                token: '',
                email: '',
                role: ''
            })
        })
    }

}

export { initState, Events };
