import React from 'react';
import { DataContext } from '../../lib/context';
import { Events } from '../../lib/events'
import { showLoader, showToast } from '../common'
import { toast } from 'react-toastify';

let timeOutId = null

export default function Table() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)

    const onClick = e => {
        showLoader(true, 'saving')
        setTimeout(() => {
            showLoader(false, 'saving')
        }, 1000)
    }
    const onClick2 = e => {
        showLoader(true)
        setTimeout(() => {
            showLoader(false)
        }, 2000)
    }
    const onClick3 = e => {
        toast.error("Wow so easy!", {
            theme: "dark"
        })
    }

    return (
        <>
            <div>Table</div>
            <button className='button' onClick={onClick}>show loader</button>
            <button className='button' onClick={onClick2}>show loader</button>
            <hr />
            <button className='button' onClick={onClick3}>show Toast</button>
        </>
    );
}
