import React from 'react';
import { DataContext } from '../lib/context';
import { Events } from '../lib/events'
import { login as dxcLogin } from '../lib/msal';
import { showLoader, showToast, clone } from './common'
//version1

export default function Login() {
  const [message, setMessage] = React.useState('')
  const [userCredential, setUserCredential] = React.useState({ email: '', password: '' })
  const { state, update } = React.useContext(DataContext);
  const events = new Events(update)

  async function handleUserLogin() {
    showLoader(true)

    const result = await events.userLogin(userCredential.email, userCredential.password)
    if (result) {
      setMessage(result.error)
    }
    showLoader(false)
  }

  async function handleDxcLogin() {
    showLoader(true)
    console.log('click', window.location)
    let result = await dxcLogin()
    if (result.data.errorCode) {
      console.log(result.data.errorCode)
    } else {
      //get init data
      const dxcToken = result.data.token
      // console.log('login.js(19) > dxctoken :', dxcToken);
      const dxcResult = await events.dxcLogin(dxcToken)
      console.log('login.js(39) > dxcResult :', dxcResult);
      if (dxcResult) {
        if (dxcResult.error) {
          console.log('error:', dxcResult)
          setMessage(dxcResult.detail)
        } else {
          console.log('Login success')
          // showToast('Welcome to AWS Dashboard!', 'info')
        }
      }
    }
    showLoader(false)
  }

  function handleUserCredentialChange(p, val) {
    console.log('login.js(40) > val :', val);
    setUserCredential(st => {
      let newSt = clone(st)
      newSt[p] = val
      return newSt
    })
  }

  //TODO: <<<Testing only 
  // async function testLogin() {
  //   const token = "d318a17b-ad42-4613-ba0e-b8acc59ecfb31323cdcd7742f95883cbbfb5-404cfb85-444e-44ab-9866-b913e163a6de064755591d00170930ab4202-0d8076d8-5c8f-4d50-bbac-25a7e75764cb810177d560ef31e90188190e"
  //   const dxcResult = await events.testLogin(token)
  //   console.log('testlong:', dxcResult);
  // }
  // React.useEffect(() => {
  //   testLogin()
  // }, [])
  //TODO: Testing only>>> 


  return (
    <div className='login-container'>
      <div
        className='bg-gray-200 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: '600px', border: '1px solid rgb(35, 64, 117 )' }}
      >
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={3}>
                <div
                  className='text-center text-gray-50 font-bold  p-2' 
                  style={{ backgroundColor: 'rgb(35, 64, 117 )' }}>
                  Welcome to the SDS Dashboard
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='p-4'>
                  <div className='font-bold'>
                    Customer Login
                  </div>
                  <div>
                    Email
                  </div>
                  <div>
                    <input type={'text'} placeholder='email'
                    className='p-1'
                     onChange={e => handleUserCredentialChange('email', e.target.value)} value={userCredential.email} />
                  </div>
                  <div>
                    Password
                  </div>
                  <div>
                    <input type={'password'}
                    className='p-1'
                    placeholder='password' onChange={e => handleUserCredentialChange('password', e.target.value)} value={userCredential.password} />
                  </div>
                  <button className='button mt-2 ' onClick={handleUserLogin}>Login</button>
                </div>
              </td>
              <td className=' w-20'>
                or
              </td>
              <td className='w-60'>
                <button
                  onClick={handleDxcLogin}
                  className='button'
                >Sign In with DXCAS SAML</button>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div
                  className='text-center pt-8 text-red-800'>
                  {message}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
