import React from 'react';
import { getBase } from '../../lib/api'
import DashboardTablet from './dashboardTablet'
import { showLoader, showToast, validateEmail, clon, wait } from '../common'


let timeOutItem = ''
let dashboardActive = false

function addTimeOutObj(name, timeOutId) {
    // console.log('home.js(11) >  addTimeOutObj:', name, timeOutId);
    timeOutItem = timeOutId
}
function removeTimeOutObj(name) {
    // let timeoutId = timeoutList[name]
    // console.log('home.js(15) > removeTimeOutObj :', timeoutId);
    clearTimeout(timeOutItem)
    timeOutItem = ''
}

function DashboardItem({ token, itemName, itemPullInterval }) {
    const [cache, setCache] = React.useState(null)
    const [error, setError] = React.useState(null)
    async function getData(retry) {
        const result = await getBase(token, '/api/dashboard?id=' + itemName)
        if (result.error) {
            if (retry < 2) {
                await wait(500)
                console.log('retry DashboardItem - getData: ', retry)
                await getData(retry + 1)
            } else {
                setError(`error loading ${itemName}`)

                let timeoutObj = setTimeout(() => getData(0), 5000)
                addTimeOutObj(itemName, timeoutObj)
            }
            // showToast(`error loading ${itemName}: ${result.error}`, 'error')
            // setCache(null)
        } else {
            if (dashboardActive) {
                setError(null)
                setCache(result)

                let timeoutObj = setTimeout(() => getData(0), 5000)
                addTimeOutObj(itemName, timeoutObj)
            }
        }

    }
    React.useEffect(() => {
        dashboardActive = true
        getData(0)
        return () => {
            dashboardActive = false
            console.log('remove timeout,', timeOutItem)
            removeTimeOutObj(itemName)
        }
    }, [])

    // console.log('getData:', itemName, cache)

    return cache ? <DashboardTablet itemName={itemName} itemNameDisplay={cache.title} cache={cache} error={error} /> : ''
}

export default React.memo(DashboardItem) 