import { PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";

function getURL() {
    const domailName = "nrrportal.azurewebsites.net"
    if (window.location.href.indexOf('localhost:3000') > -1)
        return 'http://localhost:3000';
    if (window.location.href.indexOf('localhost:3001') > -1)
        return 'http://localhost:3001'
    if (window.location.href.indexOf(domailName) > -1)
        return `https://${domailName}`
}
const msalConfig = {
    auth: {
        clientId: '2634a686-e763-4d23-ba9d-9c5ff1de03d8',   //dxcas - Near Real-time Reporting Portal
        // clientId: '4ad3f15c-86a3-49d9-a39b-57642d785d70',  //dxc - asd-client-facing-dashboard
        redirectUri: getURL()
    }
}
// const msalConfig = {
//     auth: {
//         clientId: '9a5efa09-2332-4aae-9a1d-a7c09c0c3b45',  //dxc - TVAN
//         redirectUri: (window.location.href.indexOf('localhost') > -1) ? 'http://localhost:3000' : 'https://dxctvan.azurewebsites.net'
//     }
// }

const msalRequest = {
    scopes: [
        'user.read',
        'User.ReadBasic.All'
    ]
}

const login = async function () {
    const msalClient = new PublicClientApplication(msalConfig);

    // console.log('Begin login');
    // Login
    try {
        // Use MSAL to login
        const authResult = await msalClient.loginPopup(msalRequest);

        let account = authResult.account
        let token = authResult.accessToken

        const authProvider = {
            getAccessToken: async () => {
                return token;
            }
        };

        const graphClient = Client.initWithMiddleware({ authProvider });

        //user info
        let result = await graphClient
            .api('/me')
            .select('id,displayName,mail,userPrincipalName')
            .get();

        //manager info
        let result2 = {}
        try {
            result2 = await graphClient
                .api(`/users/${result.id}/manager`)
                .select('id,displayName,mail,userPrincipalName')
                .get();
        } catch (error) {
            result2 = { id: "", displayName: "", mail: "", userPrincipalName: "" }
        }

        //finally
        return {
            err: false,
            data: {
                token,
                account,
                user: result,
                manager: result2
            }
        }
    } catch (error) {
        return {
            err: false,
            data: error
        }
    }
}

const logout = async () => {
    const msalClient = new PublicClientApplication(msalConfig);
    const authResult = await msalClient.logout()
    return authResult
}

export {
    msalConfig,
    msalRequest,
    login,
    logout
};