import { toast } from 'react-toastify';

const showLoader = (b, text = 'loading') => {
    const el = document.getElementById('loader')
    el.style.display = b ? 'block' : 'none'
    const elTxt = document.getElementById('loader-txt')
    elTxt.innerHTML = text
}

/**
 * 
 * @param {*} text 
 * @param {*} type "info|error"
 */
const showToast = (text, type) => {
    toast[type](text, {
        theme: "dark"
    })
}

const validateEmail = (email) => {
    const rg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return rg.test(email)
};

const clone = obj => JSON.parse(JSON.stringify(obj))

const wait = (ms) => new Promise((resolve, reject) => {
    setTimeout(() => resolve(), ms)
})

/* 
const obj = {
    name: 'beng',
    status: {
        "inbound": {
            "handled": 5,
            "abandoned": 4
        },
        "outbound": {
            "handled": 7,
            "abandoned": 8
        }
    }
}

getProp(obj, 'status.inbound.handled')) 
>>5

*/
const getPropValue = (obj, p) => {
    if(p.indexOf('.') > -1){
        const [newP, ...restP] = p.split('.')
        return getPropValue(obj[newP], restP.join('.'))
    }else{
        return obj[p]
    }
}

export {
    showLoader,
    showToast,
    validateEmail,
    clone,
    getPropValue,
    wait
};
