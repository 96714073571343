import React, { createContext } from 'react';
const DataContext = createContext([]);
const DataProvider = ({ children, initState }) => {
    const [state, setState] = React.useState(initState)
    const update = fn => setState(state => ({ ...state, ...fn(state) }))

    return (
        <DataContext.Provider value={
            { state, update }
        }>
            {children}
        </DataContext.Provider>
    );
}

export { DataContext, DataProvider };