import React from 'react';

import { DataContext } from '../lib/context';
import { Events } from '../lib/events'

import Header from './header'
import Footer from './footer'
import Menu from './menu'
import Login from './login'
import Users from './pages/users'
import Password from './pages/password'
import Loader from './common/loader'
import { ToastContainer } from 'react-toastify';

function App() {
    const { state, update } = React.useContext(DataContext);
    const events = new Events(update)

    const SelectedComp = state.pages[state.pageId].comp



    return (
        <>
            {state.token === '' ?
                <Login /> :
                <div className="ew-parent">
                    <div className="ew-header"><Header /></div>
                    <div className="ew-left-panel " style={{ backgroundColor: 'rgb(35, 64, 117 )'}} ><Menu /></div>
                    <div className="ew-right-panel">
                        <SelectedComp />
                    </div>
                    <div className="ew-footer">
                        <Footer />
                    </div>
                    { state.showUsers ?  <Users /> : null}
                    {state.forceChangePassword ? <Password force={true} /> : ''}
                    {state.showChangePassword ? <Password force={false} /> : ''}
                </div>}
            <Loader />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
            />
        </>
    );
}

export default App